<template>
  <div>
    <vx-card title="Payment Received">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/2 w-full vertical-divider">
          <vs-divider style="width: 95%; margin-left: 2%; margin-right: 5%"
            >Customer Data</vs-divider
          >
          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Customer</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-validate="'required'"
                name="customer"
                class="selectExample"
                v-model="create.customer"
                :options="this.$store.state.arPaymentReceive.filter.customer"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                track-by="ID"
                label="name"
                :loading="loading.customer"
                :custom-label="queryCustomer"
                :preventAutofocus="query.customer"
                @search-change="getOptionCustomer"
                @select="getShipTo"
                :disabled="viewOnly"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <span class="option__title"
                        >({{ props.option.code }}),
                        {{ props.option.name }}</span
                      >
                    </span>
                  </div>
                </template>

                <template slot="tag" slot-scope="props">
                  <div class="multiselect__tag">
                    <span>{{ props.option.name }}</span>
                    <i
                      class="multiselect__tag-icon"
                      @click.prevent
                      @mousedown.prevent.stop="
                        props.remove(props.option, $event)
                      "
                    />
                  </div>
                </template>
              </multiselect>
              <span
                v-show="errors.has('customer')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("customer") }}
              </span>
            </div>
          </div>

          <!-- SHIP TO -->
          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Ship to</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                name="shipTo"
                class="selectExample"
                v-model="selectedShipTo"
                :options="this.$store.state.arPaymentReceive.filter.shipTo"
                :multiple="true"
                :allow-empty="true"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Type to search"
                :loading="loading.shipto"
                track-by="address"
                :custom-label="queryShipto"
                @search-change="onShiptoChange"
                :disabled="viewOnly"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span
                      v-if="props.option.address != 'All'"
                      class="option__title"
                    >
                      {{ props.option.code }}
                      <br />
                      {{ props.option.address }} <br />
                      {{ props.option.sub_district }} <br />
                      {{ props.option.district }} <br />
                      {{ props.option.city }}, {{ props.option.province }},
                      {{ props.option.country }} - <br />
                      {{ props.option.postal_code }}
                    </span>
                    <span v-else> {{ props.option.address }} </span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span
                      v-if="props.option.address != 'All'"
                      class="option__title"
                    >
                      {{ props.option.code }}
                      <br />
                      {{ props.option.address }} <br />
                      {{ props.option.sub_district }} <br />
                      {{ props.option.district }} <br />
                      {{ props.option.city }}, {{ props.option.province }},
                      {{ props.option.country }} - <br />
                      {{ props.option.postal_code }}
                    </span>
                    <span v-else> {{ props.option.address }} </span>
                  </div>
                </template>
              </multiselect>
            </div>
            <span
              v-show="errors.has('shipTo')"
              class="text-danger text-sm help is-danger"
            >
              {{ errors.first("shipTo") }}
            </span>
          </div>

          <vs-divider style="width: 80%; margin-left: 2%"
            >Payment Data</vs-divider
          >

          <div
            v-if="!globalIsEmptyObject(this.selected)"
            class="vx-row"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              {{ this.selected.Code }}
            </div>
          </div>

          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Payment Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <flat-pickr
                :config="configDatePicker"
                v-validate="'required'"
                v-model="create.datePayment"
                style="width: 100%"
                :disabled="viewOnly"
              />
            </div>
          </div>

          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Payment Method</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-validate="'required'"
                class="selectExample"
                v-model="create.method"
                :options="option.method"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                @select="selectedMethod"
                :disabled="viewOnly"
                name="Payment method"
              >
              </multiselect>
              <span
                v-show="errors.has('Payment method')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("Payment method") }}
              </span>
            </div>
          </div>

          <!-- <div
            v-if="validate.paymentAccount.required"
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Payment Account</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-validate="validate.paymentAccount"
                name="paymentAccount"
                v-model="create.paymentAccount"
                :options="
                  this.$store.state.arPaymentReceive.filter.paymentAccount
                "
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                track-by="Issuer"
                label="AccountName"
                :disabled="viewOnly"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >({{ paymentMethod[props.option.Type] }}
                      {{ props.option.Issuer }} -
                      {{ props.option.AccountNumber }})
                      {{ props.option.AccountName }}
                    </span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >({{ paymentMethod[props.option.Type] }}
                      {{ props.option.Issuer }} -
                      {{ props.option.AccountNumber }})
                      {{ props.option.AccountName }}
                    </span>
                  </div>
                </template>
              </multiselect>
              <span
                v-show="errors.has('paymentAccount')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("paymentAccount") }}
              </span>
            </div>
          </div> -->

          <div
            v-if="validate.operatingUnitBank.required"
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Operating Unit Bank</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-validate="validate.operatingUnitBank"
                name="operatingUnitBank"
                class="selectExample"
                v-model="create.operatingUnitBank"
                :options="this.$store.state.arPaymentReceive.filter.ouBank"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                track-by="BankID"
                :custom-label="queryOperatingUnitBank"
                :disabled="viewOnly"
                @select="onOperatingUnitBankSelected"
              >
                <template slot="singleLabel" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title">
                      {{ dt.option.BankName }}
                      <br />
                      {{ dt.option.AccountName }}
                      <br />
                      {{ dt.option.AccountNumber }}
                    </span>
                  </span>
                </template>
                <template slot="option" slot-scope="dt">
                  <div class="option__desc">
                    <span class="option__title">
                      {{ dt.option.BankName }}
                      <br />
                      {{ dt.option.AccountName }}
                      <br />
                      {{ dt.option.AccountNumber }}
                    </span>
                  </div>
                </template>
              </multiselect>
              <span
                v-show="errors.has('operatingUnitBank')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("operatingUnitBank") }}
              </span>
            </div>
          </div>

          <div
            v-if="validate.giroBank.required"
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Giro Bank</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-validate="validate.giroBank"
                name="Giro bank"
                class="selectExample"
                v-model="create.giroBank"
                :options="option.giroBank"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                track-by="ID"
                label="Name"
                :disabled="viewOnly"
              >
              </multiselect>
              <span
                v-show="errors.has('Giro bank')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("Giro bank") }}
              </span>
            </div>
          </div>

          <div
            v-if="validate.giroNumber.required"
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Giro Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input
                v-validate="validate.giroNumber"
                name="Giro Number"
                v-model="create.giroNumber"
                class="w-full"
                :disabled="viewOnly"
              />
              <span
                v-show="errors.has('Giro Number')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("Giro Number") }}
              </span>
            </div>
          </div>

          <div
            v-if="validate.giroDate.required"
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Giro Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <flat-pickr
                :config="giroDateConfig"
                v-validate="validate.giroDate"
                v-model="create.giroDate"
                style="width: 100%"
                :disabled="viewOnly"
                name="Giro Date"
              />
              <span
                v-show="errors.has('Giro Date')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("Giro Date") }}
              </span>
            </div>
          </div>

          <div
            v-if="validate.giroValue.required"
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Giro Value</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vue-number
                v-validate="validate.giroValue"
                :customClass="`w-full`"
                v-model="create.giroValue"
                @withoutFormat="giroValueWithoutFormat"
                :masked="true"
                :disabled="viewOnly"
                name="Giro Value"
              />
              <span
                v-show="errors.has('Giro Value')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("Giro Value") }}
              </span>
            </div>
          </div>

          <div
            v-if="validate.creditNote.required"
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Credit Note</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-validate="validate.creditNote"
                name="creditNote"
                class="selectExample"
                v-model="create.creditNote"
                :options="this.$store.state.arPaymentReceive.filter.creditNote"
                :multiple="true"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                track-by="code"
                label="code"
                @select="getTotalAvailable('creditNote')"
                @search-change="onCreditNoteChange"
                :disabled="viewOnly"
                :loading="loading.creditNote"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">
                      {{ props.option.code }} -
                      {{
                        globalPriceFormat(parseFloat(props.option.available))
                      }}
                    </span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      {{ props.option.code }} -
                      {{
                        globalPriceFormat(parseFloat(props.option.available))
                      }}
                    </span>
                  </div>
                </template>
              </multiselect>
              <span
                v-show="errors.has('creditNote')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("creditNote") }}
              </span>
              <template>
                <div v-for="(cn, i) in create.creditNote" :key="key(i)">
                  Code : {{ cn ? cn.code : "" }}
                  <br />
                  Available :
                  {{ cn ? globalPriceFormat(parseFloat(cn.available)) : 0 }}
                  <hr />
                </div>
              </template>
              Total Available : {{ globalPriceFormat(this.totalAvailable) }}
            </div>
          </div>

          <div
            v-if="validate.custDeposit.required"
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Customer Deposit</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-validate="validate.custDeposit"
                name="custDeposit"
                class="selectExample"
                v-model="create.custDeposit"
                :options="this.$store.state.arPaymentReceive.filter.custDeposit"
                :multiple="true"
                :allow-empty="false"
                :group-select="false"
                :max-height="120"
                :limit="3"
                placeholder="Type to search"
                track-by="Code"
                label="Code"
                :disabled="viewOnly"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">
                      {{ props.option.Code }} -
                      {{
                        globalPriceFormat(parseFloat(props.option.DepositValue))
                      }}
                    </span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.Code }}-
                      {{
                        globalPriceFormat(parseFloat(props.option.DepositValue))
                      }}
                    </span>
                  </div>
                </template>
              </multiselect>
              <span
                v-show="errors.has('custDeposit')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("custDeposit") }}
              </span>
              <hr />
              <div v-for="(cd, i) in create.custDeposit" :key="key(i)">
                Code : {{ cd.Code }}
                <br />
                Available :
                {{ globalPriceFormat(parseFloat(cd.DepositValue)) }}
              </div>
            </div>
          </div>

          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Reference Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input
                class="w-full"
                v-model="create.referenceCode"
                :disabled="viewOnly"
              />
            </div>
          </div>

          <div
            v-if="
              !validate.custDeposit.required && !validate.creditNote.required
            "
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Payment Value</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <!-- <vs-input class="w-full" v-model="create.amount" -->
              <!-- @keyup="create.amount = globalPriceFormat(create.amount.toString())" /> -->
              <vue-number
                :customClass="`w-full`"
                v-model="create.amount"
                @withoutFormat="amountWithoutFormat"
                :masked="true"
                :disabled="viewOnly"
              ></vue-number>
            </div>
          </div>
          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Note</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-textarea
                v-model="create.note"
                :disabled="viewOnly"
              ></vs-textarea>
            </div>
          </div>

          <!-- <vs-divider style="width: 95%; margin-left: 2%;margin-right: 5%;"></vs-divider>
                    <template v-if="requiredReason.required">
                        <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
                            <div class="vx-col sm:w-1/3 w-full">
                                <span>Reason</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <multiselect v-validate="requiredReason" name="reason" class="selectExample"
                                    v-model="create.reason" :options="this.$store.state.arPaymentReceive.filter.reason"
                                    :multiple="false" :allow-empty="false" :group-select="false" :max-height="120"
                                    :limit="3" placeholder="Type to search" track-by="reason" label="reason">
                                </multiselect>
                                <span v-show="errors.has('reason')" class="text-danger text-sm help is-danger">
                                    {{ errors.first("reason") }}
                                </span>
                            </div>
                        </div>
                    </template> -->
          <vs-divider
            style="width: 95%; margin-left: 2%; margin-right: 5%"
          ></vs-divider>

          <vs-divider
            v-if="!viewOnly"
            style="width: 95%; margin-left: 2%; margin-right: 5%"
            >Attachment</vs-divider
          >
          <div
            v-if="!viewOnly"
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 100%; margin-left: 0%"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>File</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <div class="vx-col sm:w-4/5 w-full">
                <input
                  id="fileInput"
                  name="file"
                  class="w-full inputx"
                  type="file"
                  ref="file"
                  multiple="multiple"
                  accept=".jpg, .jpeg, .png .pdf"
                />
              </div>
            </div>
          </div>
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 100%; margin-left: 0%"
            v-if="!viewOnly"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span></span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <div class="vx-col sm:w-4/5 w-full">
                <vs-button class="mr-3 mb-2" @click="handleAttachment"
                  >Add Attachment</vs-button
                >
              </div>
            </div>
          </div>

          <vs-divider style="width: 95%; margin-left: 2%; margin-right: 5%"
            >List Attachment</vs-divider
          >
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 100%; margin-left: 0%"
          >
            <table class="vs-table vs-table--tbody-table">
              <template v-for="(tr, index) in fileAttachment">
                <tr
                  v-if="tr.ListID != 0"
                  class="tr-values vs-table--tr tr-table-state-null selected"
                  v-bind:key="tr.ListID"
                >
                  <td class="td vs-table--td">{{ tr.NameFile }}</td>
                  <td class="td vs-table--td">
                    <vx-tooltip
                      text="Show"
                      v-if="tr.PathFile != ''"
                      style="width: fit-content"
                    >
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        @click.stop="handleShow(tr)"
                        :disabled="viewOnly"
                      />
                    </vx-tooltip>
                  </td>
                  <td class="td vs-table--td">
                    <template>
                      <vx-tooltip text="Delete" style="width: fit-content">
                        <vs-button
                          type="line"
                          id="deleteFile"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click.stop="handleDeleteAttachment(index)"
                          :disabled="viewOnly"
                        />
                      </vx-tooltip>
                    </template>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Invoice Exchange Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                :options="invoiceExchangeNumberOptions"
                v-model="invoiceExchangeNumber"
                :multiple="false"
                :max-height="160"
                :limit="3"
                placeholder="Type Invoice Exchange Number"
                label="invoice_exchange_number"
                :loading="loading.invoiceExchangeNumber"
                @search-change="onInvoiceExchangeSearch"
                track-by="id"
              >
                <template slot="tag" slot-scope="props">
                  <div class="multiselect__tag">
                    <span>{{ props.option.invoice_exchange_number }}</span>
                    <i
                      class="multiselect__tag-icon"
                      @click.prevent
                      @mousedown.prevent.stop="
                        props.remove(props.option, $event)
                      "
                    />
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <vs-divider style="width: 95%; margin-left: 2%; margin-right: 5%"
            >Invoice Data</vs-divider
          >
          <!-- INVOICE DATA -->
          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-1/3 w-full">
              <span>Invoice Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-model="create.invoices"
                :options="this.$store.state.arPaymentReceive.invoiceOptions"
                :multiple="true"
                :max-height="160"
                :limit="3"
                placeholder="Type Invoice Code"
                label="code"
                :loading="loading.invoice"
                track-by="code"
                @search-change="onAddTag"
                @tag="onTagInput"
                @select="onInvoiceCodeSelected"
                ref="tagInput"
                :disabled="viewOnly"
              >
                <template slot="tag" slot-scope="props">
                  <div class="multiselect__tag">
                    <span>{{ props.option.code }}</span>
                    <i
                      class="multiselect__tag-icon"
                      @click.prevent
                      @mousedown.prevent.stop="
                        props.remove(props.option, $event)
                      "
                    />
                  </div>
                </template>
              </multiselect>
            </div>
          </div>

          <vs-divider style="width: 95%; margin-left: 2%; margin-right: 5%"
            >Invoice Valid</vs-divider
          >

          <div
            class="vx-row margin-btm"
            style="width: 100%; padding-bottom: 15px"
          >
            <div class="vx-col sm:w-3/3 w-full">
              <!-- <template v-for="data in this.$store.state.arPaymentReceive.selectedInvoice"> -->

              <vs-table
                stripe
                :data="this.$store.state.arPaymentReceive.selectedInvoice"
              >
                <template slot="thead">
                  <vs-th> # </vs-th>
                  <vs-th> Invoice Code </vs-th>
                  <vs-th> Remaining </vs-th>
                  <vs-th> Payment Allocate </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      {{ indextr + 1 }}
                    </vs-td>
                    <vs-td>
                      {{ tr.Code }}
                    </vs-td>
                    <vs-td>
                      {{
                        globalPriceFormat(
                          tr.Status == 1 ? tr.Total : tr.Remaining
                        )
                      }}
                    </vs-td>
                    <vs-td>
                      <div>
                        <vue-number
                          :name="tr.Code"
                          v-validate="
                            `required|between:1,${
                              tr.Status == 1
                                ? parseFloat(
                                    globalPriceFormat(tr.Total).replace(
                                      /,/g,
                                      ''
                                    )
                                  )
                                : parseFloat(
                                    globalPriceFormat(tr.Remaining).replace(
                                      /,/g,
                                      ''
                                    )
                                  )
                            }`
                          "
                          :customClass="`w-full`"
                          v-model="allocateModels[indextr].paid"
                          @withoutFormat="withoutFormat($event, indextr)"
                          @input="onInput($event, indextr)"
                          :masked="true"
                          :disabled="viewOnly"
                        ></vue-number>
                        <span
                          v-show="errors.has(tr.Code)"
                          class="text-danger text-sm help is-danger"
                        >
                          {{ errors.first(tr.Code) }}
                        </span>
                      </div>
                    </vs-td>
                  </vs-tr>
                  <vs-tr>
                    <vs-td>
                      <b>Total</b>
                    </vs-td>
                    <vs-td> </vs-td>
                    <vs-td>
                      <b>{{ globalPriceFormat(totalInvoice) }}</b>
                    </vs-td>
                    <vs-td>
                      <b>{{ globalPriceFormat(totalPaidInvoice) }}</b>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>

              <!-- <vs-card v-bind:key="data.ID" type="5">
                                    <template>
                                        Invoice Code : {{ data.Code }} <br>
                                        Invoice Total : {{ data.Total }} <br>
                                        Invoice Remaining : {{ data.Status == 1 ? data.Total : data.Remaining }} <br>
                                    </template>
                                </vs-card>

                            </template> -->
            </div>
          </div>
          <vs-divider
            style="width: 95%; margin-left: 2%; margin-right: 5%"
          ></vs-divider>
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 100%; margin-left: 0%"
            v-bind:key="'Reason' + index.toString()"
            v-for="(tr, index) in this.$store.state.arPaymentReceive
              .selectedInvoice"
          >
            <template
              v-if="
                tr.Remaining > tr.PaidWithoutFormat && tr.PaidWithoutFormat > 0
              "
            >
              <div class="flex flex-col justify-center sm:w-1/3 w-full">
                <span>reason invoice {{ tr.Code }} </span>
              </div>
              <div
                class="flex flex-row items-center justify-between gap-3 sm:w-2/3 w-full"
              >
                <div
                  class="flex flex-col justify-center items-center sm:w-3/5 w-full"
                >
                  <multiselect
                    v-validate="'required'"
                    name="reason"
                    class="selectExample"
                    v-model="tr.Reason"
                    :options="$store.state.arPaymentReceive.filter.reason"
                    :multiple="false"
                    :allow-empty="false"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    placeholder="Type to search"
                    track-by="reason"
                    label="reason"
                    :disabled="viewOnly"
                  >
                  </multiselect>
                  <span
                    v-show="errors.has('reason')"
                    class="text-danger text-sm help is-danger"
                  >
                    {{ errors.first("reason") }}
                  </span>
                </div>
                <vs-input
                  :disabled="viewOnly"
                  v-model="reasonTextModels[index]"
                />
              </div>
            </template>
          </div>
          <template>
            <div
              class="vx-row mb-3 mt-6 w-4/4"
              v-bind:key="key(tr.ID)"
              v-for="tr in this.$store.state.arPaymentReceive.selectedInvoice"
            >
              <div
                v-if="
                  tr.PaidWithoutFormat < tr.Remaining &&
                  tr.PaidWithoutFormat > 0
                "
              >
                <div class="vx-coll w-full"></div>
              </div>
            </div>
          </template>
          <template
            v-if="
              create.method != 'Credit Note' &&
              create.method != 'Customer Deposit' &&
              create.method != null
            "
          >
            <div
              class="vx-row mb-3 mt-6 w-4/4"
              style="width: 100%; margin-left: 0%"
              v-if="!viewOnly"
            >
              <vs-button class="mb-2 mr-2" v-on:click="addFieldCustDepo()"
                >Add Customer Deposit</vs-button
              >
            </div>
            <template v-if="!paymentStatus">
              <div
                v-for="(inputCust, idx) in inputsCustDepo"
                :key="'cust' + idx"
              >
                <div class="vx-row mb-3 mt-6 w-4/4">
                  <div class="w-1/3 vx-col">
                    <label class="vs-input--label">Territory</label>
                    <multiselect
                      class="selectExample"
                      v-model="inputCust.territory"
                      :options="optionOUCustDepo"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="160"
                      :limit="4"
                      placeholder=" Type to search"
                      track-by="Code"
                      label="Code"
                      selectLabel=""
                      deselectLabel=""
                      :disabled="viewOnly"
                    >
                      <template slot="singleLabel" slot-scope="dt">
                        <span class="option__desc">
                          <span class="option__title"
                            >{{ dt.option.code }} - {{ dt.option.name }}</span
                          >
                        </span>
                      </template>

                      <template slot="option" slot-scope="dt">
                        <div class="option__desc">
                          <span class="option__title"
                            >{{ dt.option.code }} - {{ dt.option.name }}</span
                          >
                        </div>
                      </template>
                    </multiselect>
                  </div>

                  <div class="w-1/3 vx-col">
                    <label class="vs-input--label">Value</label>
                    <!--@keypress="isNumber($event)" -->
                    <!-- <vs-input
                      class="w-full"
                      type="number"
                      v-model="inputCust.value"
                    /> -->
                    <vue-number
                      :customClass="`w-full`"
                      v-model="inputCust.value"
                      :masked="true"
                      :disabled="viewOnly"
                      name="Coa Value"
                    ></vue-number>
                    <span v-show="errors.has('Coa Value')" class="text-danger">
                      {{ errors.first("Coa Value") }}
                    </span>
                  </div>

                  <div class="w-1/3 vx-col">
                    <div class="whitespace-no-wrap">
                      <div class="flex mt-6">
                        <vs-button
                          style="margin-right: 5px"
                          size="small"
                          @click="removeFieldCustDepo(idx)"
                          icon-pack="feather"
                          icon="icon-minus"
                          :disabled="viewOnly"
                        >
                        </vs-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div
              class="vx-row mb-3 mt-6 w-4/4"
              style="width: 100%; margin-left: 0%"
              v-if="!viewOnly"
            >
              <vs-button class="mb-2 mr-2" v-on:click="addFieldCoa()"
                >Add Coa
              </vs-button>
            </div>
            <div v-for="(input, k) in inputsCoa" :key="'coa' + k">
              <div class="vx-row mb-3 mt-6 w-4/4">
                <div class="w-1/3 vx-col">
                  <label class="vs-input--label">COA</label>
                  <multiselect
                    v-model="input.coa_id"
                    :options="optionCoa"
                    :multiple="false"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="160"
                    :limit="4"
                    placeholder=" Type to search"
                    track-by="ID"
                    :custom-label="onQueryCoa"
                    @search-change="onCoaSearchChanges"
                    @select="onCoaSelected($event, k)"
                    :disabled="viewOnly"
                  >
                    <template slot="singleLabel" slot-scope="dt">
                      <span class="option__desc">
                        <span class="option__title"
                          >{{ dt.option.Code }} - {{ dt.option.Name }}</span
                        >
                      </span>
                    </template>

                    <template slot="option" slot-scope="dt">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ dt.option.Code }} - {{ dt.option.Name }}</span
                        >
                      </div>
                    </template>
                  </multiselect>
                  <!-- <span
                  class="text-sm text-danger"
                  v-if="coa_ == null"
                  >This field is required</span
                > -->
                </div>

                <div class="w-1/3 vx-col">
                  <label class="vs-input--label">Cost Center</label>
                  <multiselect
                    class="selectExample"
                    v-model="input.cost_center_id"
                    :options="optionCostCenter"
                    :multiple="false"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="160"
                    :limit="4"
                    placeholder=" Type to search"
                    track-by="Name"
                    label="Name"
                    :disabled="viewOnly"
                  >
                    <template slot="singleLabel" slot-scope="dt">
                      <span class="option__desc">
                        <span class="option__title">{{ dt.option.Name }}</span>
                      </span>
                    </template>

                    <template slot="option" slot-scope="dt">
                      <div class="option__desc">
                        <span class="option__title">{{ dt.option.Name }}</span>
                      </div>
                    </template>
                  </multiselect>
                  <!-- <span
                  class="text-sm text-danger"
                  v-if="costCenter == null && status_submit"
                  >This field is required</span
                > -->
                </div>

                <div class="w-1/3 vx-col">
                  <label class="vs-input--label">Territory</label>
                  <multiselect
                    class="selectExample"
                    v-model="input.territory"
                    :options="optionOU"
                    :multiple="false"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="160"
                    :limit="4"
                    placeholder=" Type to search"
                    track-by="Name"
                    label="Name"
                    selectLabel=""
                    deselectLabel=""
                    :disabled="viewOnly"
                  >
                    <template slot="singleLabel" slot-scope="dt">
                      <span class="option__desc">
                        <span class="option__title"
                          >{{ dt.option.code }} - {{ dt.option.name }}</span
                        >
                      </span>
                    </template>

                    <template slot="option" slot-scope="dt">
                      <div class="option__desc">
                        <span class="option__title"
                          >{{ dt.option.code }} - {{ dt.option.name }}</span
                        >
                      </div>
                    </template>
                  </multiselect>
                  <!-- <span
                  class="text-sm text-danger"
                  v-if="costCenter == null && status_submit"
                  >This field is required</span
                > -->
                </div>
              </div>
              <div class="vx-row mb-3 mt-6 w-4/4">
                <div class="w-1/3 vx-col">
                  <label class="vs-input--label">Value</label>
                  <!--@keypress="isNumber($event)" -->
                  <!-- <vs-input
                    class="w-full"
                    type="number"
                    v-model="input.additional_value"
                  /> -->
                  <vue-number
                    :customClass="`w-full`"
                    v-model="input.additional_value"
                    :masked="true"
                    :disabled="viewOnly"
                    v-validate="inputsCoa[k].validation"
                    name="Coa Value"
                  ></vue-number>
                  <span v-show="errors.has(`Coa Value`)" class="text-danger">
                    {{ errors.first(`Coa Value`) }}
                  </span>
                  <!-- <vs-input /> -->
                </div>
                <div class="w-1/3 vx-col">
                  <div class="whitespace-no-wrap">
                    <div class="flex mt-6">
                      <vs-button
                        style="margin-right: 5px"
                        size="small"
                        @click="removeFieldCoa(k)"
                        icon-pack="feather"
                        icon="icon-minus"
                        :disabled="viewOnly"
                      >
                      </vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <vs-divider style="width: 95%; margin-left: 2%; margin-right: 5%"
            >Total</vs-divider
          >
          <div class="vx-row mb-3 mt-6 w-4/4">
            <div class="vx-col sm:w-1/3 w-1/3">
              <vx-card subtitle="Payment" subtitleColor="" :cardBorder="true">
                {{ globalPriceFormat(totalAvailable) }}
              </vx-card>
            </div>
            <div class="vx-col sm:w-1/3 w-1/3">
              <vx-card subtitle="Allocate" subtitleColor="" :cardBorder="true">
                {{ globalPriceFormat(totalPaidInvoice) }}
              </vx-card>
            </div>
            <div class="vx-col sm:w-1/3 w-1/3">
              <vx-card
                subtitle="Remaining"
                subtitleColor=""
                :cardBorder="true"
                :cardBackground="
                  globalPriceUnformat(totalAvailable) <
                  globalPriceUnformat(totalPaidInvoice)
                    ? '#fc7b03'
                    : ''
                "
              >
                {{ globalPriceFormat(totalAvailable - totalPaidInvoice) }}
                <br />
              </vx-card>
            </div>
          </div>

          <vs-divider
            style="width: 95%; margin-left: 2%; margin-right: 5%"
          ></vs-divider>

          <div class="vx-col w-full">
            <br />
            <!-- display: block -->
            <template>
              <vs-button
                class="mb-2 mr-2"
                color="danger"
                type="border"
                v-on:click="navigateBack()"
              >
                Close
              </vs-button>
              <vs-button
                v-if="viewOnly"
                class="mb-2 mr-2"
                color="danger"
                v-on:click="handleCancel()"
              >
                Cancel
              </vs-button>
              <vs-button
                v-if="!viewOnly"
                class="mb-2 mr-2"
                v-on:click="handleSubmit(false)"
              >
                Save
              </vs-button>
              <vs-button
                v-if="!viewOnly"
                class="mb-2"
                v-on:click="handleSubmit(true)"
              >
                Save & Confirm
              </vs-button>
            </template>
          </div>
        </div>
      </div>
      <!-- <div class="flex flex-col gap-4 w-full items-end">
        <div><vs-button>Close</vs-button></div>
      </div> -->
    </vx-card>
  </div>
</template>
<script>
// import Multiselect from "vue-multiselect";
import debounce from "debounce";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueNumber from "@/components/vue-number/component.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import moment from "moment";
import returnLineItemDataCheckpointVue from "../../../customer-return/sales-return-v2/_components/return-line-item-data - checkpoint.vue";
import { search } from "core-js/fn/symbol";

export default {
  components: {
    flatPickr,
    VueNumber,
    VueTagsInput,
  },
  props: {
    selected: Object,
    // viewOnly: {
    //   type: Boolean,
    //   default: false,
    // },
    // option: Object,
  },
  created() {
    this.$store.watch(
      (state) => state.arPaymentReceive.selectedInvoice,
      (value) => {
        console.log("selected invoice listener", value);
        this.reasonTextModels = [];
        this.allocateModels = [];
        value.forEach((v) => {
          this.allocateModels.push({
            paid: this.globalPriceFormat(v.PaidWithoutFormat),
            reason: v.Reason,
          });
          this.reasonTextModels.push(v.ReasonText);
        });
        console.log("reason models", this.reasonTextModels);
      }
    );

    this.$validator.extend(this.otherIncomeValidation, {
      validate: (value) => {
        const valFloat = parseFloat(value.toString().replace(/,/g, ""));
        return valFloat >= -1000 && valFloat <= 1000;
      },
      getMessage: () => "Value for Other Income must be between (-1000) - 1000",
    });

    this.$validator.extend(this.otherExpenseValidation, {
      validate: (value) => {
        const valFloat = parseFloat(value.toString().replace(/,/g, ""));
        return valFloat >= -10000 && valFloat <= 1000;
      },
      getMessage: () =>
        "Value for Other Expense must be between (-1000) - 1000",
    });

    this.$validator.extend(this.biayaAdministrasiBankValidation, {
      validate: (value) => {
        const valFloat = parseFloat(value.toString().replace(/,/g, ""));
        return valFloat >= -25000 && valFloat <= 25000;
      },
      getMessage: () =>
        "Value for Biaya administrasi bank must be between (-25000) - 25000",
    });

    this.$validator.extend(this.biayaUmumdanAdministrasiValidation, {
      validate: (value) => {
        const valFloat = parseFloat(value.toString().replace(/,/g, ""));
        return valFloat >= -10000 && valFloat <= 10000;
      },
      getMessage: () =>
        "Value for Biaya umum dan administrasi must be between (-10000) - 10000",
    });
  },
  data() {
    return {
      paymentMethod: ["", "Real Account", "VA", "Wallet"],
      query: {
        customer: false,
      },
      available: 0,
      debounceFunction: null,
      paymentAccount: {},
      invoiceExchangeNumber: null,
      invoiceExchangeNumberOptions: [],
      create: {
        customer: null,
        shipTo: [],
        datePayment: null,
        method: null,
        invoice: "",
        invoices: [],
        invoiceData: [],
        creditNote: [],
        custDeposit: [],
        referenceCode: "",
        amount: 0,
        amountWithoutFormat: 0,
        totalInvoice: 0,
        reason: null,
        distribute: "",
        operatingUnitBank: null,
        paymentAccount: null,
        note: "",
        giroBank: null,
        giroNumber: "",
        giroDate: null,
        giroValue: 0,
      },
      shipToOptionAll: { ID: 0, address: "All" },
      validate: {
        paymentAccount: { required: false },
        custDeposit: { required: false },
        creditNote: { required: false },
        operatingUnitBank: { required: false },
        giroBank: { required: false },
        giroNumber: { required: false },
        giroDate: { required: false },
        giroValue: { required: false },
        // reason: { required: false },
      },
      option: {
        customer: [],
        method: [
          "Cash",
          "Giro",
          "Cheque",
          "Transfer",
          "Customer Deposit",
          "Credit Note",
        ],
        giroBank: [],
      },
      fileAttachment: [],
      loading: {
        customer: false,
        shipto: false,
        invoice: false,
        invoiceExchangeNumber: false,
        creditNote: false,
        customerDeposit: false,
      },
      configDatePicker: {
        // altFormat: 'F j, Y',
        // altInput: true,
        dateFormat: "d F Y",
        altFormat: "Y-m-d",
        maxDate: "today",
      },
      giroDateConfig: {
        dateFormat: "d F Y",
        altFormat: "Y-m-d",
      },
      customNotif: {
        custom: {
          distribute: {
            required: () => "All Amount must distributed",
          },
        },
      },
      viewOnly: false,
      paymentData: {},
      isQueryDone: false,
      allocateModels: [],
      reasonTextModels: [],
      optionCoa: [],
      optionCostCenter: [],
      optionOU: [],
      optionOUCustDepo: [],
      inputsCoa: [],
      inputsCustDepo: [],
      allTerritories: [],
      deletedAttachment: [],
      debounceValidate: null,
      paymentStatus: false,
      otherIncomeValidation: "otherIncomeValidation",
      otherExpenseValidation: "otherExpenseValidation",
      biayaAdministrasiBankValidation: "biayaAdministrasiBankValidation",
      biayaUmumdanAdministrasiValidation: "biayaUmumdanAdministrasiValidation",
      isGetInvoice: true,
    };
  },
  computed: {
    totalInvoice() {
      return this.$store.getters["arPaymentReceive/getTotalInvoice"];
    },
    testPaid() {
      let paidInvoice = this.$store.state.arPaymentReceive.selectedInvoice
        .map((v) => parseFloat(v.PaidWithoutFormat))
        .reduce((acc, curr) => acc + curr, 0);
      return paidInvoice;
    },
    totalPaidInvoice() {
      if (this.$store.state.arPaymentReceive.selectedInvoice.length > 0) {
        // return
        let paidInvoice = this.$store.state.arPaymentReceive.selectedInvoice
          .map((v) => parseFloat(v.PaidWithoutFormat))
          .reduce((acc, curr) => acc + curr, 0);

        return paidInvoice + parseFloat(this.grandTotalAdditional);
        // this.$store.state.arPaymentReceive.selectedInvoice[0].PaidWithoutFormat ? this.$store.state.arPaymentReceive.selectedInvoice[0].paidWithoutFormat : 0
      } else {
        return this.grandTotalAdditional;
      }
      //
    },
    totalAvailable() {
      if (
        this.create.creditNote.length === 0 &&
        this.create.custDeposit.length === 0
      ) {
        if (this.create.amountWithoutFormat > 0) {
          return this.create.amountWithoutFormat;
        }
        return 0;
      }
      console.log(this.create.creditNote, this.create.custDeposit, "cek ini");
      const cn = this.create.creditNote
        .map((v) => parseFloat(v.available))
        .reduce((acc, curr) => acc + curr, 0);
      console.log(cn, "cn");
      if (cn > 0) {
        return cn;
      }
      const cd = this.create.custDeposit
        .map((v) => parseFloat(v.DepositValue))
        .reduce((acc, curr) => acc + curr, 0);
      if (cd > 0) {
        return cd;
      }

      return 0;
    },
    requiredReason() {
      // if (this.totalInvoice > this.create.amountWithoutFormat) {
      return { required: true };
      // } else {
      //     return {required: false}
      // }
    },
    requiredAmountDistribute() {
      // if (this.totalInvoice < this.create.amountWithoutFormat && this.create.amountWithoutFormat > 0) {
      // return {required: true}
      // } else {
      return { required: false };
      // }
    },
    paidInvoice() {
      return {
        get(index) {
          return this.$store.state.arPaymentReceive.selectedInvoice[index];
        },
        set(index, value) {
          this.$store.commit("arPaymentReceive/setPaidWithoutFormat", {
            index: index,
            value: value,
          });
        },
      };
    },
    selectedShipTo: {
      get() {
        return this.create.shipTo;
      },
      set(value) {
        if (value.length === 0) {
          this.create.shipTo = [];
          this.$store.commit("arPaymentReceive/setselectedShipTo", value);
          return;
        }
        if (value[0].ID === 0) {
          value.shift();
          this.create.shipTo = value;
        } else if (value.some((st) => st.ID === 0)) {
          this.create.shipTo = [this.shipToOptionAll];
        } else {
          this.create.shipTo = value;
        }
        this.$store.commit(
          "arPaymentReceive/setselectedShipTo",
          this.create.shipTo
        );
      },
    },
    totalCustDepoValue() {
      return this.inputsCustDepo.reduce((total, input) => {
        return total + this.parseFloat(input.value);
      }, 0);
    },
    totalCoaValue() {
      return this.inputsCoa.reduce((total, input) => {
        return total + this.parseFloat(input.additional_value);
      }, 0);
    },
    grandTotalAdditional() {
      let grandTotal = 0;
      if (this.paymentStatus) {
        grandTotal = this.parseFloat(this.totalCoaValue);
      } else {
        grandTotal =
          this.parseFloat(this.totalCustDepoValue) +
          this.parseFloat(this.totalCoaValue);
      }
      return grandTotal;
    },
    // getCoaValidation(){

    // },
  },
  async mounted() {
    console.log(Event, this.create.amount);
    this.create.invoice = "";
    this.create.invoices = [];
    // this.create.shipTo = [this.shipToOptionAll];
    await this.getBank();
    await this.getOptionCostCenter();
    await this.getAllTerritories();
    await this.getOptionCoa();
    await this.getOptionOutstanding();
    this.loading.invoice = true;
    await this.$store.dispatch("arPaymentReceive/fetchInvoiceOptions");
    this.loading.invoice = false;

    this.loading.invoiceExchangeNumber = true;
    await this.fetchInvoiceExchange();
    this.loading.invoiceExchangeNumber = false;
    this.$validator.localize("en", this.customNotif);

    if (this.$route.params.id) {
      const path = this.$router.currentRoute.path.toLowerCase();
      if (!path.includes("edit")) {
        this.viewOnly = true;
      }

      const id = parseInt(atob(this.$route.params.id));
      await this.getPayment(id);
      await this.loadData();
    }

    this.isQueryDone = true;
  },
  watch: {
    "create.customer": async function () {
      await this.$store.dispatch(
        "arPaymentReceive/fetchFilterPaymentAccount",
        this.create.customer.ID
      );
      await this.$store.dispatch("arPaymentReceive/fetchCreditNote", {
        customer_id: this.create.customer.ID
      });
      await this.$store.dispatch(
        "arPaymentReceive/fetchCustDeposit",
        this.create.customer.ID
      );
    },
    "create.invoice": {
      handler: function () {
        if (!this.isQueryDone) {
          return false;
        }
        console.log("ini watch invoice", this.create.invoice);
        if (this.create.invoice.length > 0) {
          this.create.invoices.push({
            code: this.create.invoice,
          });
          this.create.invoice = "";
        }
      },
      deep: true,
    },
    "selected.ID": function () {
      if (this.globalIsEmptyObject(this.selected)) {
        this.create.invoices = [];
        this.create.invoice = "";
        return;
      } else {
        this.loadData();
      }
    },
    "create.invoices": async function () {
      if (!this.isQueryDone) {
        return false;
      }
      if (this.invoiceExchangeNumber) {
        const invCodes = this.invoiceExchangeNumber.invoice_codes.split(", ");
        if (invCodes.length !== this.create.invoices.length) {
          this.invoiceExchangeNumber = null;
        }
      }

      if (
        !this.create.customer ||
        Object.keys(this.create.customer).length === 0
      ) {
        this.isGetInvoice = false;
      }

      // const tempShipTo = [...this.create.shipTo];
      // if (tempShipTo.some((v) => v.ID === 0)) {
      //   tempShipTo.shift();
      // }
      // this.loading.invoice = true;
      // await this.$store.dispatch("arPaymentReceive/fetchInvoiceOptions", {
      //   codes:
      //     this.create.invoices.length > 0
      //       ? this.create.invoices.map((v) => v.code)
      //       : [],
      //   customer_codes:
      //     this.create.customer && Object.keys(this.create.customer).length > 0
      //       ? [this.create.customer.code]
      //       : [],
      //   status_in: [1, 2, 3],
      //   shipment_address_ids:
      //     this.create.invoices.length === 1 ? tempShipTo.map((v) => v.ID) : [],
      // });
      // this.loading.invoice = false;

      this.validateInvoices();
    },
    "create.shipTo": {
      handler: async function () {
        if (!this.isQueryDone) {
          return false;
        }
        console.log("ini dari shipto trigger");
        const tempShipTo = [...this.create.shipTo];
        if (tempShipTo.some((v) => v.ID === 0)) {
          tempShipTo.shift();
        }

        if(!this.isGetInvoice){
          this.isGetInvoice = true;
          return;
        }
        this.loading.invoice = true;
        await this.$store.dispatch("arPaymentReceive/fetchInvoiceOptions", {
          codes:
            this.create.invoices.length > 0
              ? this.create.invoices.map((v) => v.code)
              : [],
          customer_codes:
            this.create.customer && Object.keys(this.create.customer).length > 0
              ? [this.create.customer.code]
              : [],
          status_in: [1, 2, 3],
          shipment_address_ids:
            this.create.invoices.length === 1
              ? tempShipTo.map((v) => v.ID)
              : [],
        });
        this.loading.invoice = false;
      },
      deep: true,
    },
    inputsCoa: function () {
      if (this.inputsCoa.length > 0) {
        console.log(Event, this.inputsCoa.additional_value, "ini watch");
      }
    },
    inputsCustDepo: function () {
      if (this.inputsCustDepo.length > 0) {
        console.log(Event, this.inputsCustDepo.value, "ini watch");
      }
    },
    invoiceExchangeNumber() {
      if (!this.invoiceExchangeNumber) {
        return;
      }
      const invoiceCodes = this.invoiceExchangeNumber.invoice_codes.split(", ");
      this.create.invoices = invoiceCodes.map((v, i) => {
        return {
          code: v,
          id: parseInt(this.invoiceExchangeNumber.invoice_ids[i]),
        };
      });
    },
  },
  methods: {
    navigateBack() {
      return this.$router.push({ name: "ar_payment_payment_received.index" });
    },
    async loadData() {
      try {
        this.create.invoices = [];
        this.create.invoice = "";

        console.log("load data", this.paymentData);
        console.log("load data status", this.paymentData.payment.IsConfirm);
        this.paymentStatus = this.paymentData.payment.IsConfirm;
        this.create.method = this.paymentData.payment.PaymentMethod;
        this.selectedMethod(this.paymentData.payment.PaymentMethod);
        this.create.amount = this.paymentData.payment.Amount;
        this.create.amountWithoutFormat = this.paymentData.payment.Amount;

        // selected customer
        await this.getCustomers(this.paymentData.payment.CustomerCode);

        console.log(
          "Option cust",
          this.$store.state.arPaymentReceive.filter.customer
        );

        this.create.customer =
          this.$store.state.arPaymentReceive.filter.customer.find(
            (v) =>
              v.ID === this.paymentData.payment.CustomerID ||
              v.code === this.paymentData.payment.CustomerCode
          );

        console.log("customer", this.create.customer);

        console.log(
          this.paymentData.payment.CustomerID,
          this.create.customer,
          "create.customer"
        );

        await this.getShipToForView(this.create.customer);

        // const shipTo = this.$store.state.arPaymentReceive.filter.shipTo.find(
        //   (v) => v.ID == this.paymentData.payment.ShipmentToAddressID
        // );

        const shipTo = this.$store.state.arPaymentReceive.filter.shipTo.filter(
          (v) => v.ID == this.paymentData.payment.ShipmentToAddressID
        );

        // this.loading.invoice = true;
        await this.$store.dispatch("arPaymentReceive/fetchInvoiceOptions", {
          codes:
            this.create.invoices.length > 0
              ? this.create.invoices.map((v) => v.code)
              : [],
          customer_codes:
            this.create.customer && Object.keys(this.create.customer).length > 0
              ? [this.create.customer.code]
              : [],
          status_in: [1, 2, 3],
          shipment_address_ids:
            this.create.invoices.length === 1 ? shipTo.map((v) => v.ID) : [],
        });
        this.loading.invoice = false;

        console.log(
          shipTo,
          //   this.selected.ShipmentToAddressID,
          this.$store.state.arPaymentReceive.filter.shipTo
        );

        // })
        this.selectShipTo(shipTo);
        this.create.shipTo = shipTo;
        console.log(this.create.shipTo, "create.shipTo");

        console.log(
          this.paymentData.payment.Info,
          "this.paymentData.payment.Info"
        );
        if (!this.globalIsEmptyObject(this.paymentData.payment.Info)) {
          console.log(
            this.paymentData.payment.Info,
            "this.paymentData.payment.Info1"
          );
          this.paymentData.payment.Info.invoice_number.map((v) => {
            v.paid = v.Paid;
            const allocateModel = {
              paid: v.paid,
              reason: v.Reason,
              // reasonText: v.ReasonText,
            };
            this.allocateModels.push(allocateModel);
            this.reasonTextModels.push(v.ReasonText ? v.ReasonText : "");
          });

          console.log(this.reasonTextModels, "read reason model");

          this.$store.commit("arPaymentReceive/setselectedInvoice", [
            ...this.paymentData.payment.Info.invoice_number,
          ]);

          console.log(
            this.$store.state.arPaymentReceive.selectedInvoice,
            "invoice"
          );

          if (this.paymentData.payment.Info.invoice_exchange_number) {
            await this.fetchInvoiceExchange(
              this.paymentData.payment.Info.invoice_exchange_number
            );
            this.invoiceExchangeNumber = this.invoiceExchangeNumberOptions.find(
              (v) =>
                v.invoice_exchange_number ===
                this.paymentData.payment.Info.invoice_exchange_number
            );
          } else {
            this.paymentData.payment.Info.invoice_number.map((v) => {
              this.create.invoices.push({
                code: v.Code /* The visible text on display */,
                id: v.ID,
              });
            });
            await this.validateInvoices();
          }

          // validate invoice

          console.log(this.create.invoiceData, "this.create.invoiceData");

          // coa
          this.inputsCoa = [...this.paymentData.payment.Info.coa];
          if (this.inputsCoa.length > 0 && !this.inputsCoa[0].validation) {
            this.inputsCoa.forEach((v, i) => {
              this.onCoaSelected(v.coa_id, i);
            });
          }

          // cost center
          this.inputsCustDepo = [...this.paymentData.payment.Info.cust_depo];

          // ship to
          // this.create.shipTo = this.$store.state.arPaymentReceive.filter.shipTo.filter(v => v.ID == )
          this.paymentData.payment.Info.shipment_to_address_ids.map((v) => {
            let filteredData =
              this.$store.state.arPaymentReceive.filter.shipTo.filter(
                (x) => x.ID == v
              );
            // this.create.shipTo = filteredData[0]
            this.create.shipTo.push(filteredData[0]);
          });
        }

        if (
          this.paymentData.payment.PaymentMethod != "Cash" &&
          this.paymentData.payment.PaymentMethod != "Customer Deposit" &&
          this.paymentData.payment.PaymentMethod != "Credit Note"
        ) {
          // this.validate.paymentAccount = { required: true };
          console.log(
            this.$store.state.arPaymentReceive.filter.paymentAccount,
            this.paymentData.payment,
            "cek ini"
          );
          // this.create.paymentAccount =
          //   this.$store.state.arPaymentReceive.filter.paymentAccount.find(
          //     (v) =>
          //       v.AccountNumber == this.paymentData.payment.PaymentAccountNumber
          //   );

          // this.paymentAccount =
          //   this.$store.state.arPaymentReceive.filter.paymentAccount.find(
          //     (v) =>
          //       v.AccountNumber == this.paymentData.payment.PaymentAccountNumber
          //   );

          this.create.operatingUnitBank =
            this.$store.state.arPaymentReceive.filter.ouBank.find(
              (v) =>
                v.AccountNumber ===
                  this.paymentData.payment.BankAccountNumber &&
                v.BankID === this.paymentData.payment.BankID &&
                v.AccountName == this.paymentData.payment.BankAccountName
            );
        }

        if (this.paymentData.payment.PaymentMethod === "Giro") {
          const giroBank = this.paymentData.payment.Info.giro.giro_bank;
          this.create.giroBank = {
            ID: giroBank.id,
            Name: giroBank.name,
          };

          this.create.giroNumber =
            this.paymentData.payment.Info.collection_giro_number;
          this.create.giroDate = moment(
            this.paymentData.payment.Info.giro.giro_date
          ).format("DD-MMMM-YYYY");
          this.create.giroValue = this.paymentData.payment.Info.giro.giro_value;
        }
        // selected shipment t

        this.fileAttachment = [...this.paymentData.payment.Attachment];
        console.log(this.fileAttachment, "fileAttachment");

        this.create.referenceCode = this.paymentData.payment.ReferenceCode;
        this.create.datePayment = moment(
          this.paymentData.payment.DatePayment
        ).format("DD-MMMM-YYYY");

        console.log(this.create.datePayment, "this date payment");

        this.create.note =
          this.paymentData.payment.Note !== "undefined"
            ? this.paymentData.payment.Note
            : "";
        // this.create.reason = this.paymentData.payment.Info.reason;
        if (this.paymentData.payment.CreditNoteID) {
          await this.$store.dispatch("arPaymentReceive/fetchCreditNote", {
            customer_id: this.create.customer.ID,
            search: "",
            credit_note_id: this.paymentData.payment.CreditNoteID,
          });
          this.create.creditNote =
            this.$store.state.arPaymentReceive.filter.creditNote.filter(
              (v) => v.id === this.paymentData.payment.CreditNoteID
            );

          console.log(this.create.creditNote, "this.create.creditNote");
        }

        if (this.paymentData.payment.ReferenceCode) {
          this.create.custDeposit =
            this.$store.state.arPaymentReceive.filter.custDeposit.filter(
              (v) => v.Code === this.paymentData.payment.ReferenceCode
            );
        }
      } catch (e) {
        console.log(e);
      }
    },
    onInput(value, index) {
      this.$store.commit("arPaymentReceive/setPaid", {
        index: index,
        value: value,
      });
      console.log("paid : ", value);
    },
    getTotalAvailable() {
      if (
        this.create.creditNote.length === 0 &&
        this.create.custDeposit.length === 0
      ) {
        if (this.create.amountWithoutFormat > 0) {
          return this.create.amountWithoutFormat;
        }
        return 0;
      }
      console.log(this.create.creditNote, this.create.custDeposit, "cek ini");
      const cn = this.create.creditNote
        .map((v) => parseFloat(v.available))
        .reduce((acc, curr) => acc + curr, 0);
      console.log(cn, "cn");
      if (cn > 0) {
        return cn;
      }
      const cd = this.create.custDeposit
        .map((v) => parseFloat(v.DepositValue))
        .reduce((acc, curr) => acc + curr, 0);
      if (cd > 0) {
        return cd;
      }

      return 0;
    },
    handleConfirm(ID) {
      // this.closeDetail()
      this.$vs.loading;
      this.$http
        .post("/api/v1/payment/manual-receive/confirm", { id: ID })
        .then((result) => {
          //ganti
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            // this.reloadData(this.params);
          } else {
            this.handleClose();
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    amountWithoutFormat(value) {
      console.log(value, "amountWithoutFormat");
      this.create.amountWithoutFormat = value;
    },
    giroValueWithoutFormat(value) {
      console.log(value, "giroValueWithoutFormat");
      this.create.giroValue = value;
    },
    changeInvoiceTags(...args) {
      console.log(args);
      this.create.invoices = args[0];
      console.log(this.create.invoices, "this.create.invoices");
      this.validateInvoices();
    },
    validateCustomers() {
      console.log("validate customer gess");
      if (this.create.invoices.length <= 1) {
        return true;
      }
      const customerCode = this.create.invoices[0].client_code;
      const customerName = this.create.invoices[0].client_name;
      console.log(
        customerName,
        customerCode,
        this.create.invoices[0],
        this.create.invoices
      );
      const isSame = this.create.invoices.every(
        (v) => v.client_code === customerCode && v.client_name === customerName
      );
      console.log("isSame", isSame);
      return isSame;
    },
    validateInvoices() {
      if (this.debounceValidate) {
        this.debounceValidate.clear();
      }

      this.debounceValidate = debounce(async () => {
        console.log(this.create.invoices, "this.create.invoices");
        const param = {
          invoice_code: this.create.invoices.map((i) => i.code),
          customer_code:
            this.create.customer &&
            Object.keys(this.create.customer).length > 0 &&
            this.invoiceExchangeNumber === null
              ? this.create.customer.code
              : undefined,
          status_in: [1, 2, 3],
        };
        // this.loading.customer = true;
        this.$vs.loading();

        await this.$store.dispatch(
          "arPaymentReceive/fetchValidateInvoice",
          param
        );

        let optionTerritory = [];
        this.$store.state.arPaymentReceive.selectedInvoice.map((invoice) => {
          let filteredTerritory = this.allTerritories.filter(
            (x) => x.id == invoice.TerritoryID
          );
          if (filteredTerritory.length > 0) {
            optionTerritory.push(filteredTerritory[0]);
          }
          this.create.invoices.map((v) => {
            // v.territory = filteredTerritory[0];
            if (v.code === invoice.Code) {
              v.client_code = invoice.ClientCode;
              v.client_name = invoice.ClientName;
            }
          });
        });
        // if (!this.validateCustomers()) {
        // this.$vs.notify({
        //   title: "Error",
        //   text: "Invoices must have same customer",
        //   color: "danger",
        //   position: "top-right",
        //   iconPack: "feather",
        //   icon: "icon-check",
        // });
        // this.create.invoices.splice(-1, 1);
        // return;
        // }
        this.optionOU = optionTerritory;
        this.optionOUCustDepo = optionTerritory;
        this.$vs.loading.close();

        console.log(
          this.$store.state.arPaymentReceive.selectedInvoice,
          "invoice nya disini"
        );
        await this.$store.dispatch(
          "arPaymentReceive/fetchOuBank",
          this.$store.state.arPaymentReceive.selectedInvoice.length > 0
            ? this.$store.state.arPaymentReceive.selectedInvoice[0].TerritoryID
            : 0
        );

        if (
          !this.create.customer ||
          Object.keys(this.create.customer).length === 0 ||
          this.invoiceExchangeNumber !== null
        ) {
          const selectedInvoice = [
            ...this.$store.state.arPaymentReceive.selectedInvoice,
          ];

          console.log(selectedInvoice, "selectedInvoice");
          if (selectedInvoice.length > 0) {
            await this.getCustomers(
              selectedInvoice.length > 0 ? selectedInvoice[0].ClientName : null,
              null,
              selectedInvoice.length > 0 ? selectedInvoice[0].ClientCode : null
            );
            this.create.customer =
              this.$store.state.arPaymentReceive.filter.customer.find(
                (v) => v.code === selectedInvoice[0].ClientCode
              );
            await this.$store.commit(
              "arPaymentReceive/setSelectedCustomer",
              this.create.customer
            );

            await this.$store.dispatch("arPaymentReceive/fetchCreditNote", {
              customer_id: this.create.customer.ID,
              search: "",
            });

            await this.$store.dispatch(
              "arPaymentReceive/fetchCustDeposit",
              this.create.customer.ID
            );
            // await this.getShipToForView(this.create.customer);

            if (this.$store.state.arPaymentReceive.filter.shipTo.length === 2) {
              this.create.shipTo =
                this.$store.state.arPaymentReceive.filter.shipTo.filter(
                  (v) =>
                    v.ID === selectedInvoice[0].ShipmentToAddressID ||
                    v.code === this.create.customer.code
                );

              if (this.create.shipTo.length === 0) {
                this.create.shipTo = [this.shipToOptionAll];
              }

              await this.$store.commit(
                "arPaymentReceive/setselectedShipTo",
                this.create.shipTo
              );
            } else {
              this.create.shipTo = [this.shipToOptionAll];
              await this.$store.commit(
                "arPaymentReceive/setselectedShipTo",
                this.create.shipTo
              );
            }
          }
        }
        // else if (this.create.invoices.length === 0) {
        //   this.create.customer = null;
        //   await this.$store.commit(
        //     "arPaymentReceive/setSelectedCustomer",
        //     this.create.customer
        //   );
        //   this.create.shipTo = [];

        //   await this.$store.commit(
        //     "arPaymentReceive/setselectedShipTo",
        //     this.create.shipTo
        //   );

        //   this.loading.invoice = true;
        //   await this.$store.dispatch("arPaymentReceive/fetchInvoiceOptions");
        //   this.loading.invoice = false;
        // }
      }, 50);

      return this.debounceValidate();
    },
    resetValue() {
      // this.validate.paymentAccount = { required: false };
      this.validate.custDeposit = { required: false };
      this.validate.creditNote = { required: false };
      this.validate.operatingUnitBank = { required: false };
      this.validate.reason = { required: false };
      this.validate.giroBank = { required: false };
      this.validate.giroNumber = { required: false };
      this.validate.giroDate = { required: false };
      this.validate.giroValue = { required: false };

      this.available = 0;
      this.create.creditNote = [];
      this.create.custDeposit = [];

      console.log(this.validate.creditNote, "resetValue");
    },
    // totalAvailable(value) {

    //     if (value == 'creditNote') {
    //         // this.available = this.create.creditNote.
    //         // sum of create.creditNote
    //         this.available = this.create.creditNote.map(v => parseFloat(v.available)).reduce((acc, curr) => acc + curr, 0)
    //     } else if (value == 'custDeposit') {
    //         this.available = this.create.custDeposit.map(v => parseFloat(v.DepositValue)).reduce((acc, curr) => acc + curr, 0)
    //     }
    // },
    async selectedMethod(value) {
      await this.resetValue();
      console.log(this.validate, "validate after reset");
      if (value == "Customer Deposit") {
        this.validate.custDeposit = { required: true };
        this.create.amount = 0;
        this.create.amountWithoutFormat = 0;
      } else if (value == "Credit Note") {
        this.validate.creditNote = { required: true };
        this.create.amount = 0;
        this.create.amountWithoutFormat = 0;
      } else if (value === "Giro") {
        this.validate.giroBank = { required: true };
        this.validate.giroNumber = { required: true };
        this.validate.giroDate = { required: true };
        this.validate.giroValue = { required: true };
        this.validate.operatingUnitBank = { required: true };
      } else if (value != "Cash") {
        // this.validate.paymentAccount = { required: true };
        this.validate.operatingUnitBank = { required: true };
      }
      console.log(this.validate, "validate after set");
      console.log("selectedMethod-", value);
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        // this.fileAttachment = []
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
          // if (!this.globalIsEmptyObject(this.selected)) {
          //   this.uploadAttachmentAfter(this.file[i], this.selected.ID);
          // } else {
          //   // this.uploadData(i);
          //   this.fileAttachment.push({
          //     NameFile: this.file[i].name,
          //     File: this.file[i],
          //   });
          // }
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    uploadAttachmentAfter(file, id) {
      var form = new FormData();
      form.append("id", id);
      form.append("file", file);
      this.$http
        .post("/api/v1/payment/upload-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.Attachment = resp.data.attachment;
        })
        .catch((error) => {});
      // })
    },
    handleShow(file) {
      console.log(file, "file");
      if (file.path != "" && file.path !== undefined) {
        this.downloadFileAwsS3(file.path);
      } else if (file.PathFile != "" && file.PathFile !== undefined) {
        this.downloadFileAwsS3(file.PathFile);
      } else {
        let objectURL;
        console.log(file);
        objectURL = URL.createObjectURL(file.File);

        // link.download = file.name; // this name is used when the user downloads the file
        var href = objectURL;
        window.open(href, "_blank").focus();
      }
    },
    handleDeleteAttachment(index) {
      console.log("attachments", this.fileAttachment);
      const path = this.$router.currentRoute.path.toLowerCase();
      if (this.fileAttachment[index].ListID && path.includes("edit")) {
        this.deletedAttachment.push(this.fileAttachment[index].ListID);
        console.log(this.deletedAttachment, "deletedAttachment ges");
      }

      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
      // this.deleteAttachment(index);
    },
    deleteAttachment(index) {
      this.$vs.loading();
      const listID = this.fileAttachment[index].ListID;
      var form = new FormData();
      form.append("list_id", listID);
      form.append("id", this.selected.ID);
      this.$http
        .post("/api/v1/payment/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            // console.log(this.fileAttachment)
            // console.log(id)
            this.fileAttachment = this.fileAttachment.filter((v) => {
              return v.ListID != listID;
            });
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    // getOptionCustomer: _.debounce(function (val) {
    //     console.log("search")
    //     }, 2000),
    async getCustomers(val, customer_id = undefined, code = undefined) {
      try {
        let param = {
          search: val ? val : undefined,
          ids: customer_id ? [customer_id] : undefined,
          code: code ? code : undefined,
        };
        this.loading.customer = true;
        await this.$store.dispatch(
          "arPaymentReceive/fetchFilterCustomer",
          param
        );
        this.loading.customer = false;
      } catch (e) {
        console.log(e);
        this.loading.customer = false;
      }
    },
    async getShipToForView(value) {
      try {
        this.$vs.loading();
        const params = {
          id: value.ID,
          search: "",
        };

        await this.$store.dispatch(
          "arPaymentReceive/fetchFilterShipTo",
          params
        );
        await this.$store.dispatch(
          "arPaymentReceive/fetchFilterPaymentAccount",
          this.create.customer.ID
        );
        await this.$store.dispatch("arPaymentReceive/fetchCreditNote", {
          customer_id: this.create.customer.ID,
          search: "",
        });
        await this.$store.dispatch(
          "arPaymentReceive/fetchCustDeposit",
          this.create.customer.ID
        );
        await this.$store.dispatch("arPaymentReceive/fetchOuBank", 0);
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },
    async getOptionCustomer(query) {
      console.log(query, "query1");
      if (query === "") {
        return;
      }
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }
      this.debounceFunction = debounce(function (val) {
        return new Promise((resolve) => {
          console.log(val, "query");

          this.query.customer = true;
          let param = {
            search: val,
          };
          this.loading.customer = true;
          // make this with cancel token

          // this.$vs.loading;

          this.$store
            .dispatch("arPaymentReceive/fetchFilterCustomer", param)
            .then(() => {
              // this.option.customer = res.data;
              this.loading.customer = false;
              resolve(true);
            });
        });
      }, 2000);
      return this.debounceFunction(query);
    },
    onShiptoChange(search) {
      console.log("search", search);

      if (search === "") {
        return;
      }

      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }

      this.debounceFunction = debounce(() => {
        return new Promise((resolve) => {
          this.loading.shipto = true;
          const cust = this.create.customer;
          const params = {
            id: cust.ID,
            search,
          };
          this.$store
            .dispatch("arPaymentReceive/fetchFilterShipTo", params)
            .then((res) => {
              // this.option.customer = res.data;
              this.create.shipTo = null;
              this.$store.dispatch(
                "arPaymentReceive/fetchFilterPaymentAccount",
                cust.ID
              );
              this.$store.dispatch("arPaymentReceive/fetchCreditNote", {
                customer_id: this.create.customer.ID,
                search: "",
              });
              this.$store.dispatch(
                "arPaymentReceive/fetchCustDeposit",
                cust.ID
              );
              this.$store.dispatch("arPaymentReceive/fetchOuBank");
              this.loading.shipto = false;
              resolve(true);
            });
        });
      }, 500);

      this.debounceFunction();
    },
    getShipTo(value) {
      return new Promise((resolve) => {
        console.log("ini dari onSelect");
        console.log(value, "getShipTo");
        const params = {
          id: value.ID,
          search: "",
        };
        this.$store
          .dispatch("arPaymentReceive/fetchFilterShipTo", params)
          .then(async (res) => {
            // this.option.customer = res.data;
            // this.create.shipTo = null;
            this.create.shipTo = [this.shipToOptionAll];
            // this.$store.dispatch(
            //   "arPaymentReceive/fetchFilterShipTo",
            //   params
            // );

            // this.$store.dispatch(
            //   "arPaymentReceive/fetchFilterPaymentAccount",
            //   this.create.customer.ID
            // );
            // await this.$store.dispatch("arPaymentReceive/fetchCreditNote", {
            //   customer_id: this.create.customer.ID,
            //   search: "",
            // });
            // await this.$store.dispatch(
            //   "arPaymentReceive/fetchCustDeposit",
            //   this.create.customer.ID
            // );
            await this.$store.dispatch("arPaymentReceive/fetchOuBank", 0);
            if (this.create.invoices.length === 0) {
              const tempShipTo = [...this.create.shipTo];
              if (tempShipTo.some((v) => v.ID === 0)) {
                tempShipTo.shift();
              }

              this.loading.invoice = true;

              await this.$store.dispatch(
                "arPaymentReceive/fetchInvoiceOptions",
                {
                  codes:
                    this.create.invoices.length > 0
                      ? this.create.invoices.map((v) => v.code)
                      : [],
                  customer_codes:
                    this.create.customer &&
                    Object.keys(this.create.customer).length > 0
                      ? [this.create.customer.code]
                      : [],
                  status_in: [1, 2, 3],
                  shipment_address_ids:
                    this.create.invoices.length === 1
                      ? tempShipTo.map((v) => v.ID)
                      : [],
                }
              );

              this.loading.invoice = false;
            }
            resolve(true);
          });
      });
    },
    getOptionOutstanding() {
      return new Promise((resolve) => {
        this.$store.dispatch("arPaymentReceive/fetchReason").then((res) => {
          resolve(true);
        });
      });
    },
    selectShipTo(value) {
      this.$store.commit("arPaymentReceive/setselectedShipTo", value);
    },
    handleCancel() {
      this.$vs.loading();
      const params = {
        id: parseInt(atob(this.$route.params.id)),
      };
      const resp = this.$http.post(
        "/api/v1/payment/manual-receive/cancel",
        params
      );
      resp
        .then((res) => {
          this.$vs.loading.close();
          if (res.code != 200) {
            this.$vs.notify({
              title: "Warning",
              text: res.message,
              color: "warning",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Success",
              text: "Payment has been canceled",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleClose();
          }
          console.log(res);
          console.log(res.data, "res.data");
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
          this.$vs.notify({
            title: "Error",
            text: "Payment failed to cancel",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x",
          });
          this.handleClose();
        });
    },
    handleSubmit(isConfirm = false) {
      this.$vs.loading();
      console.log(Event, this.create.amount, isConfirm, "submit");
      console.log(
        JSON.stringify(this.$store.state.arPaymentReceive.selectedInvoice),
        "submit"
      );

      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.create.datePayment == null) {
            this.$vs.notify({
              title: "Error",
              text: "Payment Date is required",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
            this.$vs.loading.close();
            return;
          }
          if (
            this.globalPriceUnformat(this.totalAvailable) <
            this.globalPriceUnformat(this.totalPaidInvoice)
          ) {
            this.$vs.notify({
              title: "Error",
              text: "Total allocate Payment is more than payment",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
            this.$vs.loading.close();
            return;
          }
          if (
            this.globalPriceUnformat(this.totalAvailable) >
              this.globalPriceUnformat(this.totalPaidInvoice) &&
            this.create.creditNote.length < 1 &&
            this.create.custDeposit.length < 1
          ) {
            this.$vs.notify({
              title: "Error",
              text: "Total Payment is more than allocate payment",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
            this.$vs.loading.close();
            return;
          }

          if (
            this.create.method === "Giro" &&
            parseFloat(this.create.giroValue.toString().replace(/,/g, "")) <= 0
          ) {
            this.$vs.notify({
              title: "Error",
              text: "Giro Value must be greater than 0",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
            this.$vs.loading.close();
            return;
          }

          if (this.totalPaidInvoice <= 0) {
            this.$vs.notify({
              title: "Error",
              text: "please allocate Payment",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
            this.$vs.loading.close();
            return;
          }

          console.log(result, "result");
          console.log("submit");
          console.log(this.create.reason, "reason");
          var form = new FormData();
          console.log(this.paymentData, "paymentData");
          const path = this.$router.currentRoute.path.toLowerCase();
          if (path.includes("edit")) {
            console.log("edit");
            form.append("id", this.paymentData.payment.ID);
            let length = 0;
            let index = 0;
            this.fileAttachment.forEach((v) => {
              if (v.File) {
                form.append("file_" + index, v.File);
                length++;
                index++;
              }
            });

            form.append("file", length);

            if (this.deletedAttachment.length > 0) {
              form.append(
                "deleted_attachment",
                JSON.stringify({ deleted_list_id: this.deletedAttachment })
              );
            }
          } else {
            form.append("id", 0);
            form.append("file", this.fileAttachment.length);
            this.fileAttachment.map((v, i) => {
              console.log(i, "file");
              form.append("file_" + i, v.File);
            });
          }
          // form.append("id", 0);
          // form.append("file", this.fileAttachment.length);
          // this.fileAttachment.map((v, i) => {
          //   form.append("file_" + i, v.File);
          // });
          form.append("customer_id", this.create.customer.ID);
          form.append("customer_code", this.create.customer.code);
          form.append("customer_name", this.create.customer.name);
          // form.append("shipment_to_address_id", this.create.shipTo.ID);
          // form.append("ship_to_code", this.create.shipTo.code);
          // form.append("ship_to_address", this.create.shipTo.address);
          form.append("type", 1);
          // form.append("territory", this.create.shipTo.territory);
          form.append("date_payment", this.create.datePayment);
          form.append("method", this.create.method);
          form.append("reference_code", this.create.referenceCode);

          form.append("note", this.create.note);
          form.append("reason", JSON.stringify(this.create.reason));

          if (this.invoiceExchangeNumber) {
            form.append(
              "invoice_exchange_number",
              this.invoiceExchangeNumber.invoice_exchange_number
            );
          }

          if (this.create.shipTo.length > 0 && this.create.shipTo[0].ID !== 0) {
            form.append(
              "shipment_address_ids",
              JSON.stringify(this.create.shipTo.map((v) => v.ID))
            );
            form.append("territory", this.create.shipTo[0].territory);
          } else {
            form.append("shipment_address_ids", JSON.stringify([]));
          }

          this.$store.state.arPaymentReceive.selectedInvoice.forEach(
            (_, index) => {
              console.log("reason text ges", this.reasonTextModels[index]);
              this.$store.commit("arPaymentReceive/setReasonText", {
                index: index,
                value: this.reasonTextModels[index],
              });
            }
          );

          form.append(
            "invoice",
            JSON.stringify(this.$store.state.arPaymentReceive.selectedInvoice)
          );

          console.log(
            "invoices",
            this.$store.state.arPaymentReceive.selectedInvoice
          );

          if (!this.globalIsEmptyObject(this.create.paymentAccount)) {
            form.append(
              "payment_account",
              JSON.stringify(this.create.paymentAccount)
            );
          }

          if (this.create.creditNote.length > 0) {
            form.append("credit_note", JSON.stringify(this.create.creditNote));
            form.append("amount", this.totalPaidInvoice);
          }
          if (this.create.custDeposit.length > 0) {
            form.append(
              "cust_deposit",
              JSON.stringify(this.create.custDeposit)
            );
            form.append("amount", this.totalPaidInvoice);
          }
          if (this.create.operatingUnitBank) {
            form.append(
              "operating_unit_bank",
              JSON.stringify(this.create.operatingUnitBank)
            );
          }
          form.append("amount", this.create.amountWithoutFormat);

          console.log(this.inputsCustDepo, "this.inputsCustDepo");
          console.log(this.inputsCoa, "this.inputsCoa");

          if (this.inputsCoa.length > 0) {
            for (let i = 0; i < this.inputsCoa.length; i++) {
              console.log(
                this.allocateModels[i],
                "this.allocateModels[i].additional_value"
              );
              this.inputsCoa[i].additional_value = this.globalPriceUnformat(
                this.inputsCoa[i].additional_value
              );
            }

            form.append("coa", JSON.stringify(this.inputsCoa));
            // form.append("territory", this.inputsCoa[0].territory.id);
          } else {
            form.append("coa", JSON.stringify([]));
          }

          if (this.inputsCustDepo.length > 0) {
            for (let i = 0; i < this.inputsCustDepo.length; i++) {
              this.inputsCustDepo[i].value = this.globalPriceUnformat(
                this.inputsCustDepo[i].value
              );
            }
            form.append("cust_depo", JSON.stringify(this.inputsCustDepo));
            // form.append("territory", this.inputsCustDepo[0].territory.id);
          } else {
            form.append("cust_depo", JSON.stringify([]));
          }

          if (this.create.method === "Giro") {
            const giroBank = {
              id: this.create.giroBank.ID,
              name: this.create.giroBank.Name,
            };
            form.append("giro_bank", JSON.stringify(giroBank));
            form.append("giro_number", this.create.giroNumber);
            form.append(
              "giro_date",
              moment(this.create.giroDate).format("YYYY-MM-DD")
            );
            form.append("giro_value", this.create.giroValue);
          }

          const resp = this.$http.post("/api/v1/payment/manual-receive", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          resp
            .then((res) => {
              this.$vs.loading.close();
              if (res.code <= 299) {
                this.$vs.notify({
                  title: "Success",
                  text: "Payment has been saved",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                if (isConfirm) {
                  if (!this.globalIsEmptyObject(this.selected)) {
                    this.handleConfirm(this.selected.ID);
                  } else {
                    res.data.payment.map((v) => {
                      this.handleConfirm(v.ID);
                    });
                    // this.handleConfirm(res.data.payment.ID);
                  }
                } else {
                  this.handleClose();
                }
              } else if (res.code > 299) {
                this.$vs.notify({
                  title: "Error",
                  text: res.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }
              // else {
              //   this.$vs.notify({
              //     title: "Success",
              //     text: "Payment has been saved",
              //     color: "success",
              //     position: "top-right",
              //     iconPack: "feather",
              //     icon: "icon-check",
              //   });
              //   if (isConfirm) {
              //     if (!this.globalIsEmptyObject(this.selected)) {
              //       this.handleConfirm(this.selected.ID);
              //     } else {
              //       this.handleConfirm(res.data.payment.ID);
              //     }
              //   } else {
              //     this.handleClose();
              //   }
              // }
              console.log(res);
              console.log(res.data, "res.data");
            })
            .catch((err) => {
              this.$vs.loading.close();
              console.log(err);
              this.$vs.notify({
                title: "Error",
                text: "Payment failed to save",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x",
              });
              this.handleClose();
            });
        } else {
          this.$vs.loading.close();
          // loop this.errors
          // this.errors
          // console.log(this.errors, "errors")
          for (let i = 0; i < this.errors.items.length; i++) {
            console.log(this.errors.items[i].field);
            console.log(this.errors.items[i].msg);
            this.$vs.notify({
              title: "Error",
              text: this.errors.items[i].msg,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
          }
        }
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("closeDetail", true);
      return this.$router.push({ name: "ar_payment_payment_received.index" });
    },
    queryCustomer({ code, name }) {
      return `(${code}), ${name}`;
    },
    // queryShipTo({ code, address }) {
    //   return `(${code}), ${address}`;
    // },
    async getPayment(id) {
      try {
        this.$vs.loading();
        const resp = await this.$http.get(`/api/v1/payment/${id}`);
        console.log(resp);
        this.paymentData = resp.data;
        this.fileAttachment = resp.data.attachment;

        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },
    // async getBankForm() {
    //   // this.$http.get('/api/v1/cash-bank/form-bank/' + this.operatingUnit.OperatingUnitTerritory.TerritoryID).then((r) => {
    //   this.$vs.loading();
    //   this.$http
    //     .get("/api/v1/cash-bank/form-bank/0")
    //     .then((r) => {
    //       // this.optionTerritory = r.data.territory
    //       // this.optionTerritory = r.data.territories
    //       // this.optionBank = r.data.bank
    //       // console.log(r)
    //       this.optionOperatingUnitBank = r.data.operatingUnitBank;
    //       this.operatingUnitBank = r.data.operatingUnitBank[0];
    //       this.$vs.loading.close();
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       this.$vs.loading.close();
    //     });
    // },
    key(id) {
      //generate random number that really random using id and random string
      return id + Math.random().toString(36).substring(7);
    },
    queryShipto({
      code,
      address,
      sub_district,
      district,
      city,
      province,
      country,
      postal_code,
    }) {
      return address && address !== "All"
        ? `(${code}) ${address} ${sub_district} ${district} ${city} ${province} ${country} ${postal_code}`
        : "All";
    },
    withoutFormat(val, index) {
      console.log("emitted value", val, index);
      this.$store.commit("arPaymentReceive/setPaidWithoutFormat", {
        index: index,
        value: parseFloat(val),
      });
    },
    onTagInput(tag) {
      const tagObject = {
        ID: this.create.invoices.length + 1,
        code: tag,
      };
      this.create.invoices.push(tagObject);
    },
    async onAddTag(tag) {
      console.log(tag);
      const tags = tag.replace(/\s+/g, "").trim().split(";");

      if (tag.includes(";")) {
        for (let i = 0; i < tags.length; i++) {
          const tagObject = {
            ID: this.create.invoices.length + 1,
            code: tags[i],
          };
          this.create.invoices.push(tagObject);
        }

        this.$refs.tagInput.deactivate();
        this.loading.invoice = true;

        await this.$store.dispatch("arPaymentReceive/fetchInvoiceOptions", {
          codes: this.create.invoices.map((v) => v.code),
          status_in: [1, 2, 3],
          customer_codes:
            this.create.customer && Object.keys(this.create.customer).length > 0
              ? [this.create.customer.code]
              : [],
        });

        await this.validateInvoices();

        this.loading.invoice = false;
      } else {
        if (tag.length < 3 && tag.length > 0) {
          return;
        }

        if (this.debounceFunction) {
          this.debounceFunction.clear();
        }
        this.debounceFunction = debounce(async () => {
          this.loading.invoice = true;
          await this.$store.dispatch("arPaymentReceive/fetchInvoiceOptions", {
            // codes: this.create.invoices.map((v) => v.code),
            status_in: [1, 2, 3],
            customer_codes:
              this.create.customer &&
              Object.keys(this.create.customer).length > 0
                ? [this.create.customer.code]
                : [],
            search: tag ? tag : null,
          });
          this.loading.invoice = false;
        }, 500);
        this.debounceFunction();
      }

      // this.$vs.loading();
      // const resp = await this.getInvoiceFilter(tag);
      // if (resp.code === 200) {
      //   this.invoiceCodeOptions = resp.data;
      // } else {
      //   this.invoiceCodeOptions = [];
      // }
      // this.$vs.loading.close();
    },
    async onInvoiceSelected() {
      const payload = {
        codes: this.create.invoices.map((v) => v.Code),
        status_in: [1, 2, 3],
        customer_codes:
          this.create.customer && Object.keys(this.create.customer).length > 0
            ? [this.create.customer.code]
            : [],
      };
      this.loading.invoice = true;
      await this.$store.dispatch(
        "arPaymentReceive/fetchInvoiceOptions",
        payload
      );
      this.loading.invoice = false;
      await this.validateInvoices();
    },
    onCoaSearchChanges(query) {
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }
      this.debounceFunction = debounce(async () => {
        this.getOptionCoa(query);
      }, 800);
      this.debounceFunction();
    },
    async getOptionCoa(query = "") {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/chart-of-account", {
          params: {
            order: "code",
            sort: "asc",
            search: query,
            length: 50,
            codes: [
              "71901100", //Other Income
              "71901101", //Other Expense
              "70119100", //Biaya Administrasi Bank
              "70119300", //Biaya Umum dan Administrasi
            ],
          },
        });

        if (resp.code <= 299) {
          this.optionCoa = resp.data.records.map((v) => {
            return v.coa;
          });
          console.log(this.optionCoa, "optionCoa ges");

          // let validationName = "";

          // this.optionCoa.forEach((v) => {
          //   const key = v.Code;
          //   switch (v.Code) {
          //     case "71901100":
          //       validationName = this.otherIncomeValidation;
          //       break;
          //     case "71901101":
          //       validationName = this.otherExpenseValidation;
          //       break;
          //     case "70119100":
          //       validationName = this.biayaAdministrasiBankValidation;
          //       break;
          //     case "70119300":
          //       validationName = this.biayaUmumdanAdministrasiValidation;
          //       break;
          //     default:
          //       validationName = "";
          //   }
          //   this.coaValidation[key] = validationName;
          // });
        }
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading();
      }
      // this.$vs.loading();
      // this.$http
      //   .get("/api/v1/master/chart-of-account", {
      //     params: {
      //       order: "code",
      //       sort: "asc",
      //       search: query,
      //       length: 50,
      //     },
      //   })
      //   .then((resp) => {
      //     if (resp.code == 200) {
      //       this.optionCoa = resp.data.records.map((v) => {
      //         return v.coa;
      //       });
      //       console.log(this.optionCoa, "optionCoa ges");
      //     } else {
      //       this.$vs.notify({
      //         title: "Error",
      //         text: "Failed to get Coa option",
      //         color: "danger",
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-check",
      //       });
      //     }
      //     this.$vs.loading.close();
      //   });
    },
    async getOptionCostCenter() {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
            search: "D1TRNF",
          },
        });
        if (resp.code <= 299) {
          this.optionCostCenter = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Cost Center option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
      // this.$vs.loading();
      // this.$http
      //   .get("/api/v1/master/cost-center", {
      //     params: {
      //       order: "name",
      //       sort: "asc",
      //     },
      //   })
      //   .then((resp) => {
      //     if (resp.code == 200) {
      //       this.optionCostCenter = resp.data.records;
      //     } else {
      //       this.$vs.notify({
      //         title: "Error",
      //         text: "Failed to get Cost Center option",
      //         color: "danger",
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-check",
      //       });
      //     }
      //     this.$vs.loading.close();
      //   });
    },
    async getAllTerritories() {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        });

        if (resp.code <= 299) {
          this.allTerritories = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Cost Center option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
      // this.$vs.loading();
      // this.$http
      //   .get("/api/v1/master/territory", {
      //     params: {
      //       order: "name",
      //       sort: "asc",
      //     },
      //   })
      //   .then((resp) => {
      //     if (resp.code == 200) {
      //       this.allTerritories = resp.data.records;
      //     } else {
      //       this.$vs.notify({
      //         title: "Error",
      //         text: "Failed to get Cost Center option",
      //         color: "danger",
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-check",
      //       });
      //     }
      //     this.$vs.loading.close();
      //   });
    },
    getInvoiceExchange(params) {
      return this.$http.get("/api/v1/customer-invoice-exchange/for-options", {
        params,
      });
    },
    async fetchInvoiceExchange(params) {
      try {
        // this.$vs.loading();
        const resp = await this.getInvoiceExchange(params);
        if (resp.code === 200) {
          this.invoiceExchangeNumberOptions = resp.data;
        } else {
          this.invoiceExchangeNumberOptions = [];
        }
        // this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        // this.$vs.loading.close();
      }
    },
    onInvoiceExchangeSearch(data = "") {
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }
      this.debounceFunction = debounce(async () => {
        this.loading.invoiceExchange = true;
        await this.fetchInvoiceExchange({ search: data });
        this.loading.invoiceExchange = false;
      }, 500);
      this.debounceFunction();
    },
    addFieldCustDepo() {
      this.inputsCustDepo.push({
        value: 0,
        territory: null,
      });
      console.log(this.inputsCustDepo, "removeFieldCustDepo");
    },
    removeFieldCustDepo(index) {
      this.inputsCustDepo.splice(index, 1);
      console.log(index, "removeFieldCustDepo");
    },
    addFieldCoa() {
      this.inputsCoa.push({
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
        territory: null,
        validation: null,
      });
      console.log(this.inputsCoa, "removeFieldCoa");
    },
    removeFieldCoa(index) {
      this.inputsCoa.splice(index, 1);
      console.log(index, "removeFieldCoa");
    },
    onCoaSelected(selectedCoa, index) {
      const code = selectedCoa ? selectedCoa.Code : "";
      switch (code) {
        case "71901100":
          this.inputsCoa[
            index
          ].validation = `${this.otherIncomeValidation}|required`;
          break;
        case "71901101":
          this.inputsCoa[
            index
          ].validation = `${this.otherExpenseValidation}|required`;
          break;
        case "70119100":
          this.inputsCoa[
            index
          ].validation = `${this.biayaAdministrasiBankValidation}|required`;
          break;
        case "70119300":
          this.inputsCoa[
            index
          ].validation = `${this.biayaUmumdanAdministrasiValidation}|required`;
          break;
        default:
          this.inputsCoa[index].validation = null;
      }
    },
    showErrorWithoutNumber(error) {
      if (error) {
        return error.replace(/[0-9]/g, "");
      } else {
        return null;
      }
    },
    parseFloat(value) {
      if (typeof value === "string") {
        // Remove commas and parse as float
        return parseFloat(value.replace(/,/g, "")) || 0;
      }
      return parseFloat(value) || 0;
    },
    onQueryCoa({ Code, Name }) {
      if (!Code || !Name) {
        return "";
      }
      return `(${Code}), ${Name}`;
    },
    async getBank() {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/bank", {
          params: {
            order: "id",
            sort: "desc",
            page: 1,
            length: 9999,
          },
        });

        if (resp.code <= 299) {
          this.option.giroBank = resp.data.records;
        } else {
          this.option.giroBank = [];
        }

        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },
    onOperatingUnitBankSelected(value) {
      if (this.create.method !== "Giro") {
        return;
      }

      if (!value) {
        this.create.giroBank = null;
      } else {
        console.log(value, "value bank account");
        this.create.giroBank = this.option.giroBank.find(
          (v) => v.ID === value.BankID
        );

        if (!this.create.giroBank) {
          this.$vs.notify({
            title: "Error",
            text: "Bank not found",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x",
          });
        }
      }
    },
    queryOperatingUnitBank({ BankName, AccountNumber, AccountName }) {
      return `${BankName} - ${AccountNumber} - ${AccountName}`;
    },
    onInvoiceCodeSelected() {
      console.log("onInvoiceCodeSelected");
      this.validateInvoices();
    },

    async fetchCreditNote(search = "") {
      await this.$store.dispatch("arPaymentReceive/fetchCreditNote", {
        customer_id: this.create.customer.ID,
        search,
      });
      console.log("cn", this.$store.state.arPaymentReceive.filter.creditNote);
    },

    async fetchCustDeposit(search = "") {
      await this.$store.dispatch("arPaymentReceive/fetchCustDeposit", {
        customer_id: this.create.customer.ID,
        search,
      });
    },

    onCreditNoteChange(search) {
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }
      this.debounceFunction = debounce(async () => {
        this.loading.creditNote = true;
        await this.fetchCreditNote(search);
        this.loading.creditNote = false;
      }, 500);
      this.debounceFunction();
    },
  },
  destroyed() {
    this.$store.commit("arPaymentReceive/clearState");
  },
};
</script>
<style scoped>
.vertical-divider {
  border-right: 1px solid #626262;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
</style>
